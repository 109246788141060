import config from '~/utils/config'

const state = () => ({
  obj: {}
})

const getters = {
  obj(state) {
    return state.obj
  }
}

const actions = {
  async getInspectionByCarId(vuex, id) {
    try {
      const res = await this.$axios.$get(`${config.api.inspection}/${id}`)
      return Promise.resolve(res)
    } catch (err) {
      return Promise.resolve(err.response?.data)
    }
  },
}

export default {
  state, actions, getters
}
