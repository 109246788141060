import appConfig from '@/utils/config'
import {getSignature} from '@/utils'
import {FAKE_DEVICEID, TOKEN_KEY, VISITOR_ID} from '@/utils/constants'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import {ACTION_SET_TOKEN} from '@/utils/store.action'
import CryptoJS from "crypto-js";
import * as AxiosLogger from 'axios-logger';

const ENABLE_LOG_FETCH = !!+process.env.NUXT_ENV_ENABLE_LOG_FETCH
const cryptoSetting = {
  algorithm: 'aes-256-ctr',
  secretKey: appConfig.secretKey
}
const decryptData = (response) => {
  if (response && response.data && response.data.d && response.data.c) {
    const {c, d} = response.data
    const {secretKey} = cryptoSetting
    const key = CryptoJS.enc.Hex.parse(secretKey);
    const iv = CryptoJS.enc.Hex.parse(c);
    const decrypted = CryptoJS.AES.decrypt(d.toString(), key, {
      mode: CryptoJS.mode.CTR,
      iv: iv,
      padding: CryptoJS.pad.NoPadding
    });
    let obj = {}
    try {
      obj = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
    } catch (e) {
      console.error(e)
    }
    response.data = obj
  }
  return response
}
export default function ({$axios, store, app, $cookies}) {
  const refreshAuthLogic = async failedRequest => {
    const code = parseInt(failedRequest.response && failedRequest.response.status)
    switch (code) {
      case appConfig.httpCode.UNAUTHORIZED:
        let visitorId = Math.random().toString(36)
        if (process.client) {
          visitorId = $cookies.get(VISITOR_ID)
        } else {
          visitorId = app.$cookies.get(VISITOR_ID)
        }
        if (!visitorId) {
          visitorId = Math.random().toString(36)
        }
        if (!process.client) {
          app.$cookies.set(FAKE_DEVICEID, "1")
        }

        const data = {
          deviceType: appConfig.deviceType.WEB,
          deviceId: visitorId,
          versionCode: '2'
        }
        data.signature = getSignature(data)
        return $axios.$post(appConfig.api.auth.enterGuest, data).then(res => {
          if (process.client) {
            $axios.setHeader('x-access-token', res.token)
            $cookies.set(TOKEN_KEY, res.token)
            store.dispatch('authen/checkGuest', true)
          } else if (process.server) {
            app.$axios.setHeader('x-access-token', res.token)
            app.$cookies.set(TOKEN_KEY, res.token)
            store.dispatch('authen/checkGuest', true)
          }
        })
      case appConfig.httpCode.TOKEN_EXPIRED:
        try {
          const res = await $axios.$post(appConfig.api.auth.refreshToken, {})
          const token = res.token
          store.dispatch(ACTION_SET_TOKEN, token)
          $axios.setHeader('x-access-token', token)
        } catch (e) {

        }
        return Promise.resolve()
    }
  }
  $axios.onRequest(config => {
    let token
    if (process.client) {
      token = store.state.authen.token || $cookies.get(TOKEN_KEY)
    } else if (process.server) {
      token = app.$cookies.get(TOKEN_KEY)
    }
    if (token) {
      config.headers = {
        ...config.headers,
        'x-access-token': token
      }
    }

    if (config.data && (config.method.toUpperCase() === 'POST' || config.method.toUpperCase() === 'PUT' || config.method.toUpperCase() === 'DELETE')) {
      delete config.data.signature
      config.data.signature = getSignature(config.data)
    }
  })
  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    error.response = decryptData(error.response)
    switch (code) {
      case appConfig.httpCode.BAD_REQUEST:
        // redirect('/400')
        break
      case appConfig.httpCode.TOKEN_EXPIRED:
        break
      case appConfig.httpCode.UNAUTHORIZED:
        if (error.config && error.config.url === appConfig.api.auth.refreshToken) {
          if (process.client) {
            $cookies.remove(TOKEN_KEY)
            store.dispatch('authen/removeUserInfo')
            store.dispatch('authen/removeToken')
            // if (!error.config.headers["x-access-token"] ||(error.config.headers.common &&!error.config.headers.common["x-access-token"]))
            window.location.reload()
          } else if (process.server) {
            app.$cookies.remove(TOKEN_KEY)
          }
          refreshAuthLogic(error).then()
        }
        break
      default:
        break
    }
  })
  $axios.onResponse(response => {
    response = decryptData(response)
    return response
  })
  console.log("c")
  if (ENABLE_LOG_FETCH) {
    $axios.interceptors.request.use(AxiosLogger.requestLogger, AxiosLogger.errorLogger);
    $axios.interceptors.response.use((response) => {
      return AxiosLogger.responseLogger(response, {
        data: false,
        dateFormat: 'HH:MM:ss',
      })
    }, AxiosLogger.errorLogger);
  }
  createAuthRefreshInterceptor($axios, refreshAuthLogic, {
    statusCodes: [appConfig.httpCode.TOKEN_EXPIRED, appConfig.httpCode.UNAUTHORIZED],
    retryInstance: $axios
  })
}
