import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  brandApp: [],
})


const mutations = {
  SET_BRAND_APP(sate, payload) {
    sate.brandApp = payload
  }
}

const getters = {
  brandApp(state) {
    return state.brandApp
  }
}

const actions = {
  async getBrandApp({commit}, query = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data: {data}} = await this.$axios({
          url: `${config.api.brandSellCar}?${queryString.stringify(query)}`,
          method: 'get'
        })
        commit('SET_BRAND_APP', data)
        resolve(data)
      } catch (err) {
        resolve([])
      }
    })
  },
}

export default {
  state, mutations, actions, getters
}
