import Vue from 'vue'
import VueFbCustomerChat from 'vue-fb-customer-chat'

// if (process.env.NODE_ENV === "production") {
//   Vue.use(VueFbCustomerChat, {
//     page_id: process.env.NUXT_ENV_FACEBOOK_PAGE_ID,
//     theme_color: '#1C2C5E',
//     locale: 'vi_VN',
//   })
// }
