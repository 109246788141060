import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  banners: [],
})


const mutations = {
  SET_BANNERS(state, payload) {
    state.banners = payload
  },
}

const getters = {
  banners(state) {
    return state.banners
  }
}

const actions = {
  async getBanners({ commit }) {
    const query = {
      // channel: 'website',
    }
    try {
      const { home_page } = await this.$axios.$get(`${config.api.banner}?${queryString.stringify(query)}`)
      const banners = (home_page && home_page.length) ? home_page[0]?.banners : []
      commit('SET_BANNERS', banners)
      return Promise.resolve(banners)
    } catch (err) {
      return Promise.resolve([])
    }
  },

  async getBannersListCars({ commit }) {
    const query = {
      channel: 'website',
    }
    try {
      const { buy_car } = await this.$axios.$get(`${config.api.bannerListCars}?${queryString.stringify(query)}`)
      const banners = (buy_car && buy_car.length) ? buy_car[0]?.banners : []
      return Promise.resolve(banners)
    } catch (err) {
      return Promise.resolve([])
    }
  },

  async getBannersCustom({ commit }, {screen, position}) {
    try {
      const res = await this.$axios.$get(`${config.api.banners}?screen=${screen}&position=${position}`)
      return Promise.resolve(res.data)
    } catch (err) {
      return Promise.resolve([])
    }
  }
}

export default {
  state, mutations, actions, getters
}
