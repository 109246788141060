import config from "@/utils/config";
import queryString from 'query-string'
const actions = {
  async sendApplication({commit}, params) {
    try {
      const res = await this.$axios.$post(`${config.api.application}`, params)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
}

export default {actions}
