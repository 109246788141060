import config from '~/utils/config'
import queryString from 'query-string'

const state = () => ({
  campaign: {},
  carsCampaign: []
})


const mutations = {
  SET_CAMPAIGN(sate, payload) {
    sate.campaign = payload
  },
  SET_CARS_CAMPAIGN(sate, payload) {
    sate.carsCampaign = payload
  }
}

const getters = {
  campaign(state) {
    return state.campaign
  },
  carsCampaign(state) {
    return state.carsCampaign
  }
}

const actions = {
  async getCampaginById({commit}, id, query = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await this.$axios({
          url: `${config.api.campaign}/${id}?${queryString.stringify(query)}`,
          method: 'get'
        })
        commit('SET_CAMPAIGN', data || {})
        commit('SET_CARS_CAMPAIGN', data?.cars || [])
        resolve(data)
        return data
      } catch (err) {
        resolve([])
      }
    })
  },
}

export default {
  state, mutations, actions, getters
}
