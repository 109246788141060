import config from "@/utils/config";
import queryString from 'query-string'
import {FAVORITE_CARS, USER_KEY} from "@/utils/constants";

const state = () => {
  return {
    cars: [],
    favoriteCars: [],
    compareCars: [],
    filterProps: {},
    queryFilter: {}
  }
}

const actions = {
  async getCarAttributes() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$get(config.api.carAttributes)
        resolve(res)
      } catch (err) {
        resolve(err)
      }
    })
  },
  async getCarsHome(vuex, data) {
    return new Promise((resolve, reject) => {
      this.$axios({
        url: `${config.api.car}?limit=6&sort=desc`,
        method: 'get'
      })
        .then((response) => {
          if(response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          resolve({})
        });
    })
  },
  async getCarList(vuex, query = {}) {
    return new Promise((resolve, reject) => {
      this.$axios({
        url: `${config.api.searchCar}?${queryString.stringify(query)}`,
        method: 'get'
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },
  async addInforSellCar(vuex, data) {
    return new Promise((resolve, reject) => {
      this.$axios({
        url: `${config.api.sellCar}`,
        method: 'post',
        data
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          resolve(error.response?.data);
        });
    })
  },

  async searchCarByName(vuex, query = {}) {
    if(query){
      return new Promise((resolve, reject) => {
        this.$axios({
          url: `${config.api.searchCar}?${queryString.stringify(query)}`,
          method: 'get'
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    }
  },
  async filterCar(vuex, query = {}) {
    return new Promise((resolve, reject) => {
      this.$axios({
        url: `${config.api.searchCar}?${queryString.stringify(query)}`,
        method: 'get'
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  },

  setFilterProps(vuex, payload) {
    vuex.commit('SET_FILTER_PROPS', payload)
  },
  setQueryFilter(vuex, payload) {
    vuex.commit('SET_QUERY_FILTER', payload)
  },

  async getCarsFiltered(vuex, query = {}) {
    if(query){
      return new Promise((resolve, reject) => {
        this.$axios({
          url: `${config.api.car}?${queryString.stringify(query)}`,
          method: 'get'
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            resolve([])
          });
      })
    }
  },

  getLead({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$post(config.api.lead, data)
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  },

  getFavoriteCars({ commit }, params = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$get(`${config.api.favoriteCar}?${queryString.stringify(params)}`)
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  },

  addFavoriteCar({ commit }, payload) {
    // commit('ADD_FAVORITE_CAR', payload)

    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$put(config.api.favoriteCar, payload)
        resolve(res)
      } catch(err) {
        reject(err)
      }
    })
  },
  removeFavoriteCar({ commit }, payload) {
    commit('REMOVE_FAVORITE_CAR', payload)
  },
  setFavoriteCars({ commit }, payload) {
    commit('SET_FAVORITE_CARS', payload)
  },
  addCompareCar({ commit }, payload) {
    commit('ADD_COMPARE_CAR', payload)
  },
  removeCompareCar({ commit }, payload) {
    commit('REMOVE_COMPARE_CAR', payload)
  },

  // buy car
  buyCar({ commit }, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$post(config.api.lead, data)
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  },

  getSearchHistory({ commit }, params = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$get(`${config.api.searchHistory}?${queryString.stringify(params)}`)
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  },

  deleteAllSearchHistory({ commit }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$delete(config.api.searchHistory)
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  },

  getSuggestSearch({ commit }, params = {}) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$get(`${config.api.suggestion}?${queryString.stringify(params)}`)
        resolve(res)
      } catch (err) {
        reject(err)
      }
    })
  },
}
const mutations = {
  ADD_FAVORITE_CAR(state, payload) {
    const car = state.favoriteCars.find(car => car.id === payload.id) || {}
    if(!car.id) {
      state.favoriteCars = [payload, ...state.favoriteCars]
    }
  },
  REMOVE_FAVORITE_CAR(state, payload)  {
    const i = state.favoriteCars.map(item => item.id).indexOf(payload.id);
    state.favoriteCars.splice(i, 1);
  },
  SET_FAVORITE_CARS(state, payload) {
    state.favoriteCars = payload
  },
  ADD_COMPARE_CAR(state, payload) {
    const index = state.compareCars.indexOf(payload)

    if(state.compareCars.length < 2 && index === -1) {
      state.compareCars.push(payload)
    }
  },
  REMOVE_COMPARE_CAR(state, payload) {
    const i = state.compareCars.map(item => item.id).indexOf(payload.id);
    state.compareCars.splice(i, 1);
  },
  SET_FILTER_PROPS(state, payload) {
    state.filterProps = payload
  },
  SET_QUERY_FILTER(state, payload) {
    state.queryFilter = payload
  }
}

const getters = {
  favoriteCars(state) {
    return state.favoriteCars
  },
  compareCars(state) {
    return state.compareCars
  },
  filterProps(state) {
    return state.filterProps
  },
  queryFilter(state) {
    return state.queryFilter
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
