import config from '~/utils/config'
import {GUEST_KEY, TOKEN_KEY, USER_KEY, VISITOR_ID} from '@/utils/constants'
import firebase from 'firebase/compat'
import {FacebookAuthProvider, GoogleAuthProvider} from 'firebase/auth'
import moment from 'moment'
import eventEmitter from "~/utils/eventEmitter";

const platform = require('platform')

const state = () => ({
  intervalPing: null,
  token: null,
  isGuest: true,
  userInfo: null,
  isLoggedIn: false,
  visitorId: null,
  visibleTransitionGuest: false,
  transitionGuestData: {
    leads: [],
    favorites: [],
    sellCars: []
  }
})

const mutations = {
  TOGGLE_TRANSITION_GUEST_MODAL(state, payload) {
    state.visibleTransitionGuest = !state.visibleTransitionGuest
    if (state.visibleTransitionGuest && payload) {
      state.transitionGuestData = payload
    } else {
      state.transitionGuestData = {
        leads: [],
        favorites: [],
        sellCars: []
      }
    }
  },
  SET_VISISTOR_ID(state, payload) {
    state.visitorId = payload
    if (process.client) {
      localStorage.setItem(VISITOR_ID, JSON.stringify(payload))
    }
  },
  SET_USERINFO(state, payload) {
    state.userInfo = payload
    if (process.client) {
      localStorage.setItem(USER_KEY, JSON.stringify(payload))
    }
  },
  REMOVE_USERINFO(state) {
    state.userInfo = {}
    if (process.client) {
      localStorage.removeItem(USER_KEY)
    }
  },
  SET_TOKEN(state, payload) {
    state.token = payload
    this.$cookies.set(TOKEN_KEY, payload)
    if (process.client) {
      localStorage.setItem(TOKEN_KEY, payload)
    }
  },
  REMOVE_TOKEN(state) {
    state.token = null
    this.$cookies.remove(TOKEN_KEY)
    if (process.client) {
      localStorage.removeItem(TOKEN_KEY)
    }
  },
  CHECK_GUEST(state, payload) {
    state.isGuest = payload
    this.$cookies.set(GUEST_KEY, payload)
  },
  CHANGE_LOGIN_STATUS(state, status) {
    state.isLoggedIn = status
  },
  SET_INTERVAL_PING(state, payload) {
    state.intervalPing = payload
  }
}

const getters = {
  token(state) {
    return state.token
  },
  isGuest(state) {
    return state.isGuest
  },
  userInfo(state) {
    return state.userInfo
  },
  isLoggedIn(state) {
    return state.isLoggedIn
  },
  visibleTransitionGuest(state) {
    return state.visibleTransitionGuest
  },
  transitionGuestData(state) {
    return state.transitionGuestData
  },
  intervalPing(state) {
    return state.intervalPing
  },
}

const actions = {
  setVistorId({commit}, data) {
    commit('SET_VISISTOR_ID', data)
  },
  setUserInfo({commit}, data) {
    commit('SET_USERINFO', data)
  },
  removeUserInfo({commit}) {
    commit('REMOVE_USERINFO')
  },
  setToken({commit}, token) {
    commit('SET_TOKEN', token)
  },
  checkGuest({commit}, payload) {
    commit('CHECK_GUEST', payload)
  },
  removeToken({commit}) {
    commit('REMOVE_TOKEN')
  },
  changeLoginStatus({commit}, status) {
    commit('CHANGE_LOGIN_STATUS', status)
  },
  toggleTransitionGuestModal({commit}, payload) {
    commit('TOGGLE_TRANSITION_GUEST_MODAL', payload)
  },

  async checkExistence({dispatch}, dataSubmit) {
    try {
      const res = await this.$axios.$post(config.api.auth.checkExistence, dataSubmit)
      const {data, isExist} = res
      return Promise.resolve(res)
    } catch (err) {
      return Promise.resolve(err.response?.data)
    }
  },

  async enterGuest({commit, dispatch}) {
    try {
      const vistorId = await dispatch('getVisitorId')
      const data = {
        deviceName: platform.description,
        deviceType: config.deviceType.WEB,
        deviceId: vistorId,
        versionCode: '1'
      }
      const {token} = await this.$axios.$post(config.api.auth.enterGuest, data)
      if (token) {
        commit('CHECK_GUEST', true)
        commit('SET_TOKEN', token)
      }
      return Promise.resolve({token})
    } catch (err) {
      return Promise.resolve(err)
    }
  },
  async ping({dispatch, commit, getters}) {
    if (getters['intervalPing']) clearInterval(getters['intervalPing']);

    const interval = setInterval(async () => {
      try {
        const res = await this.$axios.$get(config.api.auth.ping)
      } catch (err) {
        const {data, status} = err.response

        if (status === config.httpCode.UNAUTHORIZED) {
          await dispatch('logout')
          clearInterval(getters['intervalPing'])
        }

        if (data && data.msg) {
          window.alert(data.msg)
        }
      }
    }, config.intervalPing)
    commit('SET_INTERVAL_PING', interval)
  },
  signInWithVETC({commit, dispatch}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const vistorId = await dispatch('getVisitorId')
        const dataSm = {
          deviceName: platform.description,
          deviceType: config.deviceType.WEB,
          versionCode: '1',
          method: 'vetc',
          deviceId: vistorId,
          ...payload
        }
        const data = await this.$axios.$post(config.api.auth.login, dataSm)
        if (data.token) {
          dispatch('ping')
          commit('SET_USERINFO', data.user)
          commit('SET_TOKEN', data.token)
          commit('CHECK_GUEST', false)
          // const user = data.user
          // // rudder event login
          // rudderanalytics?.track(
          //   config.newEventName.login_clicked,
          //   {
          //     method: 'phone',
          //     status: 'pass',
          //     name: user.fullName,
          //     phone: user.phoneNumber,
          //     user_name: user.username,
          //     gender: user.gender,
          //     userID: user.uid,
          //     forumUserID: user.forumUserId,
          //     account_number: user?.vetcInfo
          //   }
          // )
        }
        resolve(data)
      } catch (err) {
        resolve({ok: false})
      }
    })
  },
  async login({commit, dispatch}, dataSubmit) {
    try {
      // const { data: transactionGuest } = await dispatch('transactionGuest')
      // if(transactionGuest) {
      //   commit('TOGGLE_TRANSITION_GUEST_MODAL', transactionGuest)
      // }

      const data = await this.$axios.$post(config.api.auth.login, dataSubmit)
      if (data.token) {
        const { uid, user } = data || {}
        eventEmitter.emit('identifyUser', {uid, user})
        dispatch('ping')

        commit('SET_USERINFO', data.user)
        commit('SET_TOKEN', data.token)
        commit('CHECK_GUEST', false)
        // rudderanalytics?.track(
        //   config.newEventName.login_clicked,
        //   {
        //     method: 'phone',
        //     status: 'pass',
        //     name: user.fullName,
        //     phone: user.phoneNumber,
        //     user_name: user.username,
        //     gender: user.gender,
        //     userID: user.uid,
        //     forumUserID: user.forumUserId,
        //     account_number: user?.vetcInfo
        //   }
        // )
        // await this.$axios.$put(`${config.api.transactionGuest}/merge`)
        return Promise.resolve({data, status: config.httpCode.OK})
      } else {
        return Promise.resolve({status: config.httpCode.BAD_REQUEST})
      }
    } catch (err) {
      alert(err.response?.data?.msg)
      return Promise.resolve({status: err.response?.status})
    }
  },
  getVisitorId({dispatch}) {
    try {
      if (process.client) {
        const visitorId = this.$cookies.get(VISITOR_ID)
        if (visitorId)
          return visitorId
        return Math.random().toString(36)
      }
      const visitorId = this.app.$cookies.get(VISITOR_ID)
      if (visitorId) {
        return visitorId
      }
      return Math.random().toString(36)
    } catch (err) {
      return Math.random().toString(36)
    }
  },

  async signInWithFirebase({dispatch}, token) {
    try {
      const vistorId = await dispatch('getVisitorId')
      const data = {
        deviceName: platform.description,
        deviceType: config.deviceType.WEB,
        deviceId: vistorId,
        versionCode: '1',
        method: 'firebase',
        token
      }
      return await dispatch('login', data)
    } catch (err) {
      return Promise.reject(err)
    }
  },

  async register({commit, dispatch}, data) {
    try {
      const {ok, user} = await this.$axios.$put(`${config.api.auth.user}/updateWhenRegister`, data)
      if (ok) {
        commit('SET_USERINFO', user)
        return Promise.resolve({ok: true})
      } else {
        return Promise.resolve({ok: false})
      }
    } catch (err) {
      return Promise.resolve({ok: false, status: err.response?.status})
    }
  },

  async logout({router, dispatch, commit, getters}) {
    await this.$axios.$post(config.api.auth.logout)
    clearInterval(getters['intervalPing'])
    commit('SET_INTERVAL_PING', null)
    dispatch('removeUserInfo')
    dispatch('removeToken')
    await dispatch('enterGuest')
  },

  requestOTP({commit}, data = {}) {
    const {mobile, appVerifier} = data
    const phoneNumber = '+84' + mobile
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$fire.auth.signInWithPhoneNumber(phoneNumber, appVerifier)
        resolve(res)
      } catch (err) {
        resolve(err)
      }
    })
  },

  async validateOTP({commit}, data) {
    const {session_id, otp, ...other} = data
    try {
      this.$axios.setHeader('App-Id', 'vetc.custom.app')
      this.$axios.setHeader('Session-Id', session_id)
      this.$axios.setHeader('Otp', otp)

      const res = await this.$axios.$post(config.api.auth.validateOTP, {...other})
      return Promise.resolve(res)
    } catch (err) {
      return Promise.resolve(err)
    }
  },

  signInWithGoogle({commit, dispatch}) {
    return new Promise(async (resolve, reject) => {
      try {
        const googleProvider = new GoogleAuthProvider()
        this.$fire.auth.signInWithPopup(googleProvider).then(async result => {
          const {user: {multiFactor: {user: {accessToken}}}} = result
          const res = await dispatch('signInWithFirebase', accessToken)
          if (res.status !== config.httpCode.OK && res.status !== config.httpCode.CREATED) {
            resolve({ok: false})
          } else {
            resolve({ok: true, data: res})
          }
        }).catch(error => {
          resolve({ok: false})
        })
      } catch (err) {
        resolve({ok: false})
      }
    })
  },

  signInWithFacebook({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      try {
        const facebookProvider = new FacebookAuthProvider()
        this.$fire.auth.signInWithPopup(facebookProvider).then(async result => {
          const {user: {multiFactor: {user: {accessToken}}}} = result

          const res = await dispatch('signInWithFirebase', accessToken)
          if (res.status !== config.httpCode.OK && res.status !== config.httpCode.CREATED) {
            resolve({ok: false})
          } else {
            resolve({ok: true, data: res})
          }
        }).catch(error => {
          // Handle Errors here.
          resolve({ok: false})
        })
      } catch (err) {
        resolve({ok: false})
      }
    })
  },

  loginSSO({dispatch}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await this.$axios.$post(config.api.auth.loginSSO, payload)
        if (data.access_token) {
          dispatch('setUserInfo', data)
          dispatch('setToken', data.access_token)
        }
        resolve(data)
      } catch (err) {
        resolve(err)
      }
    })
  },

  updateUserInfo({commit}, payload) {
    commit('SET_USERINFO', payload)
  },

  transactionGuest({commit}) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$get(config.api.transactionGuest)
        resolve(res)
      } catch (err) {
        resolve(err)
      }
    })
  },

  getDtail({commit}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$post(config.api.auth.oauth, payload)
        resolve(res)
      } catch (err) {
        resolve(err)
      }
    })
  },

  getOauth({commit}, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$post(config.api.auth.oauth, payload)
        resolve(res)
      } catch (err) {
        resolve(err)
      }
    })
  },

  getOauthClient({commit}, clientId) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$get(`${config.api.auth.clientOauth}?clientId=${clientId}`)
        resolve(res)
      } catch (err) {
        resolve(err)
      }
    })
  },

  signInEmail({commit}, data) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await this.$axios.$post(`${config.api.base}/emailLead`, {
          ...data
        })
        resolve(res)
      } catch (err) {
        resolve(err)
      }
    })
  }

}

export default {
  state, mutations, actions, getters
}
