import config from '~/utils/config'

const state = () => ({
  dataGeneral: [],
  dataBuyCar: [],
  dataSellCar: [],
  dataQA: []
})


const mutations = {
  GET_GENERAL_FAQ(state, payload) {
    state.dataGeneral = payload
  },
  GET_BUY_CAR_FAQ(state, payload) {
    state.dataBuyCar = payload
  },
  GET_SELL_CAR_FAQ(state, payload) {
    state.dataSellCar = payload
  },
  GET_QA_FAQ(state, payload) {
    state.dataQA = payload
  }
}

const getters = {
  dataGeneral(state) {
    return state.dataGeneral
  },
  dataBuyCar(state) {
    return state.dataBuyCar
  },
  dataSellCar(state) {
    return state.dataSellCar
  },
  dataQA(state) {
    return state.dataQA
  }
}

const actions = {
  async raiseQuestion(vuex, data) {
   const temp = data
    try {
      const res = await this.$axios.$post(config.api.faq, temp)
      const {data} = res
      return Promise.resolve(res)
    } catch (err) {
      return Promise.resolve(err.response?.data)
    }
  },
  async getQuestion({commit}, {type, page}) {
    try {
      let res = undefined
      if(page) {
        res = await this.$axios.$get(`${config.api.faq}?category=${type}&page=${page}`)
      }
      else {
        res = await this.$axios.$get(`${config.api.faq}?category=${type}`)
      }
      const {data} = res
      if(type === 4) {
        commit('GET_GENERAL_FAQ', data)
      }
      else if(type === 1) {
        commit('GET_BUY_CAR_FAQ', data)
      }
      else if(type === 2) {
        commit('GET_SELL_CAR_FAQ', data)
      }
      else if(type === 3) {
        commit('GET_QA_FAQ', data)
      }
      return Promise.resolve(res)
    } catch (err) {
      return Promise.resolve(err.response?.data)
    }
  }
}

export default {
  state, mutations, actions, getters
}
