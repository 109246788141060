import config from "@/utils/config"
import queryString from 'query-string'

const state = () => ({
  currentCar: {},
})

const mutations = {
  SET_CURRENT_CAR(state, payload) {
    state.currentCar = payload
  },
}

const actions = {
  setCurrentCar({ commit }, data) {
    commit('SET_CURRENT_CAR', data)
  },
  getCarDetails({ commit }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.$axios.$get(`${config.api.car}/${id}`)
        resolve(response)
      } catch (err) {
        resolve({})
      }
    })
  },
  getCarsByIds({ commit }, ids) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.$axios.$get(`${config.api.car}/list?ids=${ids}`)
        resolve(response)
      } catch (err) {
        resolve({})
      }
    })
  }
}

const getters = {
  currentCar(state) {
    return state.currentCar
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
