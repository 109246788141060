import config from "@/utils/config";
import queryString from 'query-string'

function initState() {
  return {
    contacts: []
  }
}

const state = initState;


const actions = {
  async getContactCarpla() {
    return new Promise((resolve, reject) => {
      this.$axios({
        url: `${config.api.base}/contactCarpla`,
        method: 'get'
      })
        .then((response) => {
          if(response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          resolve({})
        });
    })
  },
  async getContact(vuex, data) {
    return new Promise((resolve, reject) => {
      this.$axios({
        url: `${config.api.base}/contact?limit=10`,
        method: 'get'
      })
        .then((response) => {
          if(response && response.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          resolve({})
        });
    })
  },
  async addContact(vuex, contact) {
    // console.log(contact)
    return new Promise((resolve, reject) => {
      this.$axios({
        url: `${config.api.base}/contact`,
        method: 'post',
        data: contact
      })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
const mutations = {}

const getters = {}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
