import config from "@/utils/config";
import queryString from 'query-string'
const actions = {
  async getDetailCerticied({commit}) {
    try {
      const res = await this.$axios.$get(`${config.api.detailCerticied}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
  async getInspection({commit}, id) {
    try {
      const res = await this.$axios.$get(`${config.api.inspection}/${id}`)
      return Promise.resolve(res)
    } catch (e) {
      return Promise.resolve(e.response?.data)
    }
  },
}

export default {actions}
