import Vue from 'vue';
import { ValidationObserver, ValidationProvider, extend, configure } from 'vee-validate';
import { required, regex } from "vee-validate/dist/rules";
import { localize } from 'vee-validate';
import vi from 'vee-validate/dist/locale/vi.json';

// Add a rule.
extend("required", {
  ...required,
  message: val => 'Vui lòng nhập ' + val
});
extend("regex", regex);

configure({
  normalizedEvents: ['change']
})


localize('vi', vi);

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
